import React from 'react'
import './BigHeader.scss'
import Flask from '../../assets/flask.svg'
import Burger from './Burger'
import Img from 'gatsby-image'
import {graphql, StaticQuery} from 'gatsby'
import Link from './Link'

class BigHeader extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      scrolled: false,
      expanded: false,
    }
  }

  componentDidMount() {
    this.scrollCheck()
    window.onscroll = () => this.scrollCheck()
  }

  scrollCheck() {
    if (window.scrollY > 0) {
      this.setState({scrolled: true})
    } else {
      this.setState({scrolled: false})
    }
  }

  toggleNav() {
      this.setState({expanded: !this.state.expanded})
  }

  render() {
    const {mockup, background} = this.props.data
      const navClasses = (this.state.scrolled ? 'scrolled ':'') + (this.state.expanded ? 'expanded ' : '') + 'content-padding'
    return (
      <header className="big" id="top">
          <nav className={navClasses}>
          <div className="logo">
            <Link to="/#top">
              <img className="logo-icon" src={Flask} alt="logo-icon"/>
            </Link>
            <span className="logo-name">scenelab.io</span>
          </div>
          <Burger onClick={() => this.toggleNav()}/>
          <ul className="nav-links">
            <Link to="/#features" >
              <li>Features</li>
            </Link>
            <Link to="/#usecases">
              <li>Use Cases</li>
            </Link>
            <Link to="/#pricing" >
              <li>Pricing</li>
            </Link>
            <Link to="/blog">
              <li>Blog</li>
            </Link>
            <Link className="button-bg grey" to="https://app.scenelab.io">
                <li>Open App</li>
            </Link>
          </ul>
        </nav>
        <div className="hero-area content-padding">
          <div className="hero-text">
            <h1>Create realistic mockups and customized brand designs online<span className="blue">.</span></h1>
            <span>SceneLab is a graphics editor providing hundreds of design elements and templates paired with features that allow anyone to create professional designs</span>
            <Link to="https://app.scenelab.io">
              <button>start creating</button>
            </Link>
          </div>
          <div className="mockup">
            <Img fadeIn={false} loading="eager" alt="app_screenshot" fluid={mockup.childImageSharp.fluid}/>
          </div>
        </div>
        <div className="bg-overlap"/>
        <Img fluid={background.childImageSharp.fluid} className="headerbg" style={{position: 'absolute'}}/>
      </header>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query BigHeaderQuery {
        mockup: file(relativePath: { eq: "mockup_new.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1272, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
        background: file(relativePath: { eq: "headerbg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <BigHeader data={data}/>
    )}
  />
)
