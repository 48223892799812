import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Link from '../components/Link'

import Layout from '../components/Layout'

import './index.scss'
import BigHeader from '../components/BigHeader'
import FakeSearch from "../components/FakeSearch"
import UsecasePreview from '../components/UsecasePreview'

/* eslint-disable jsx-a11y/anchor-is-valid */

class Index extends React.Component {

  render() {
    const { site, templates, editorPreview, dropPreview, projectsPreview, exportPreview,
      textPreview, colorizePreview, colorizePreviewBefore } = this.props.data
    const templateImages = templates.edges.map(({ node }) => node.childImageSharp.fluid)

    return (
      <Layout title={site.siteMetadata.title}>
        <BigHeader />

        <section className="templates content-padding text-center">
          <div className="headline">
            <h2>Ever-growing Template Gallery</h2>
            <p>Get started with one of our editable templates and customize them to your needs.<br />
          High-quality mockups, brand presentations and social media templates are waiting for you!</p>
          </div>
          <div className="grid content-container">
            <div>
              <Link className="card" to="https://app.scenelab.io/editor?t=e9165553-179c-4b1e-8d7d-e4546e779f1a" blank={true}>
                <Img fluid={templateImages[0]} alt="template-thumbnail" />
              </Link>
              <Link className="card" to="https://app.scenelab.io/editor?t=fde5b643-d64d-4f85-84a4-9f900eff5dfb" blank={true}>
                <Img fluid={templateImages[7]} alt="template-thumbnail" />
              </Link>

            </div>
            <div>
              <Link className="card" to="https://app.scenelab.io/editor?t=cd0e7318-f8bb-45e2-9435-296c698dfb29" blank={true}>
                <Img fluid={templateImages[2]} alt="template-thumbnail" />
              </Link>
              <Link className="card" to="https://app.scenelab.io/editor?t=5ea0c258-9414-4fa2-9d81-695d6859cfc4" blank={true}>
                <Img fluid={templateImages[3]} alt="template-thumbnail" />
              </Link>

            </div>
            <div>
              <Link className="card" to="https://app.scenelab.io/editor?t=6971115e-1158-477b-b02d-a8bab504996b" blank={true}>
                <Img fluid={templateImages[5]} alt="template-thumbnail" />
              </Link> 
              <Link className="card" to="https://app.scenelab.io/editor?t=c9e8eb34-0ee2-4cfa-9973-be05510e60a2" blank={true}>
                <Img fluid={templateImages[4]} alt="template-thumbnail" />
              </Link>
            </div>
            <div>
              <Link className="card" to="https://app.scenelab.io/editor?t=3bbf195b-b7c5-4e81-9981-00d2dc306202" blank={true}>
                <Img fluid={templateImages[1]} alt="template-thumbnail" />
              </Link> 
              <Link className="card" to="https://app.scenelab.io/editor?t=89053bea-4828-43aa-aed1-0087f99769f6" blank={true}>
                <Img fluid={templateImages[6]} alt="template-thumbnail" />
              </Link>             
            </div>
          </div>
          <Link to="https://app.scenelab.io"><button>browse all templates</button></Link>
        </section>

        <section id="features" className="content-padding bg-lighter">
          <div className="headline text-center">
            <h2>Online Mockups Made Easy</h2>
            <p>Create personalized designs using advanced customizations. <br />Achieve professional results even without design skills.</p>
          </div>

          <div className="subsection content-container">
            <div className="flex column justifyCenter">
              <h3 className="new-banner">Place your own images on mockups per drag &amp; drop</h3>
              <p>That's literally all you have to do. Enjoy your customized designs, we take care of the rest. Also, all images get uploaded and added to your asset library automatically.</p>
              <ul>
                <li>Realistic lighting and texture</li>
                <li>Perspective transformations</li>
                <li>Automatically fit in size</li>
                <li>Access your uploaded images any time</li>
              </ul>
            </div>
            <div>
              <Img fluid={dropPreview.childImageSharp.fluid} alt="drag &amp; drop preview" />
            </div>

          </div>

          <div className="subsection content-container">
            <div className="colorizePreview row2">
              <Img className="after" fluid={colorizePreview.childImageSharp.fluid} alt="colorize elements preview" />
              <div className="before">
                <Img fluid={colorizePreviewBefore.childImageSharp.fluid} alt="colorize elements preview before" />
              </div>
            </div>
            <div className="flex column justifyCenter">
              <h3>Change colors of various items to create unique designs</h3>
              <p>We want your projects to fit your idea and brand, so we made items colorable.</p>
              <ul>
                <li>Colorize individual parts</li>
                <li>Choose color via colorpicker</li>
                <li>Open on a separate canvas for advanced edits</li>
              </ul>
            </div>
          </div>

          <div className="subsection content-container">
            <div className="flex column justifyCenter">
              <h3>Get your message across with text elements</h3>
              <p>No separate tool. No font installations. Write your content directly where you design.</p>
              <ul>
                <li>Choose from a variety of fonts</li>
                <li>Edit texts inline via double-click</li>
                <li>Adjust common font settings</li>
              </ul>
            </div>
            <div>
              <Img className="textPreview" fluid={textPreview.childImageSharp.fluid} alt="text elements preview" />
            </div>
          </div>
        </section>

        <UsecasePreview></UsecasePreview>

        <section className="content-padding bg-lighter">
          <div className="content-container">
            <div className="headline text-center">
              <h2>Interactive Web Application</h2>
              <p>SceneLab works in your browser, which means you can use it right away without having to install any software or download gigabytes of image assets.</p>
            </div>

            <div className="subsection">
              <div className="row2">
                <Img className="editorPreview" fluid={editorPreview.childImageSharp.fluid} alt="editor-ui-and-features" />
              </div>
              <div className="flex column justifyCenter">
                <h3>Feature-rich editor with adjustable settings</h3>
                <p>We strive to offer a seamless designing experience while also providing essential features to give you full control over your work.
              All changes are applied in real time, so tweaking the settings can be an explorative and fun process.</p>
                <ul>
                  <li>Transform &amp; align items intuitively</li>
                  <li>Adjust shadows, blendmodes &amp; opacities</li>
                  <li>Set background to color, texture or transparent</li>
                  <li>Undo or redo all changes</li>
                </ul>
              </div>

            </div>

            <div className="subsection content-container">
              <div className="flex column justifyCenter">
                <h3>All your designs in one place</h3>
                <p>View all projects, open them up to make edits or create a copy to build multiple variants. Your changes are saved automatically, so your progress can never get lost.</p>
                <ul>
                  <li>Auto-save</li>
                  <li>Edit &amp; copy projects</li>
                  <li>Custom project sizes</li>
                </ul>
              </div>
              <div>
                <Img className="projectsPreview" fluid={projectsPreview.childImageSharp.fluid} alt="project list interface" />
              </div>
            </div>

            <div className="subsection content-container">
              <div className="row2">
                <Img className="exportPreview" fluid={exportPreview.childImageSharp.fluid} alt="project list interface" />
              </div>
              <div className="flex column justifyCenter">
                <h3>Export high resolution images</h3>
                <p>Click export to generate an image for download that you can share or use for your work.</p>
                <ul>
                  <li>PNG files for transparency support</li>
                  <li>Optionally export twice as large</li>
                  <li>Instant download</li>
                </ul>
              </div>

            </div>

          </div>
        </section>

        <FakeSearch />

        <section id="pricing" className="text-center pricing bg-darker content-padding">
          <div className="headline">
            <h2>Pricing Plans</h2>
            <p>Not sure yet? You can try SceneLab for free <br /> and signup to save your scenes for later plan upgrades.</p>
          </div>

          <div className="grid content-container">
            <div className="plan free extended">
              <span className="plan-type">Free</span>
              <div className="plan-price">
                <span>$0</span>
                <span>/ month</span>
              </div>
              <ul className="plan-desciption">
                <li>access to full item library</li>
                <li className="lock"><i className="icon-lock"></i>export limited to 800px</li>
                <li className="lock"><i className="icon-lock"></i>free templates only</li>
                <li className="lock"><i className="icon-lock"></i>watermarks on customizations</li>
                <li className="lock"><i className="icon-lock"></i>upload up to 20 own assets</li>
                <li className="lock"><i className="icon-lock"></i>no transparent backgrounds</li>
              </ul>
              <div>
                <a href="https://app.scenelab.io" target="_blank" rel="noopener noreferrer">
                  <button>start creating</button>
                </a>
              </div>
            </div>

            <div className="plan sub">
              <span className="plan-type">SceneLab Pro</span>
              <div className="plan-price">
                <span>$14.99</span>
                <span>/ month</span>
              </div>
              <ul className="plan-desciption">
                <li>access to full item library</li>
                <li>high resolution exports</li>
                <li>free &amp; premium templates</li>
                <li>no watermarks</li>
                <li>upload up to 500 own assets</li>
                <li>transparent backgrounds</li>
              </ul>
              <div>
                <a href="https://app.scenelab.io/become-pro" target="_blank" rel="noopener noreferrer">
                  <button>become subscriber</button>
                </a>
              </div>
            </div>
          </div>


        </section>
      </Layout>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      fragment site on Site {
        siteMetadata {
          title
          description
          author
          keywords
          siteUrl
        }
      }
      query {
        site {
          ...site
        }
        templates: allFile(filter: {relativeDirectory: {eq: "templates"}}, sort: {fields: name}) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        textPreview: file(relativePath: { eq: "text_preview.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        colorizePreview: file(relativePath: { eq: "colorize_preview.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        colorizePreviewBefore: file(relativePath: { eq: "colorize_preview_before.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        dropPreview: file(relativePath: { eq: "drop_preview.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        editorPreview: file(relativePath: { eq: "editor_preview.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        projectsPreview: file(relativePath: { eq: "projects.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        exportPreview: file(relativePath: { eq: "export.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <Index data={data} />
    )}
  />
)
