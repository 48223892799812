import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import { idAndFluid } from '../utils/selectors';
import './FakeSearch.scss';

/* Search-bar animation loop */
const addText = ' green';
const chars = addText.length;
const keypressDuration = 80;
const loadingTime = 300;
const animationDuration = chars * keypressDuration + loadingTime;

const categories = [
  { name: 'Art & Design', icon: 'art', color: '#caa32a' },
  { name: 'Beauty & Cosmetics', icon: 'beauty', color: '#b2517e' },
  { name: 'Food', icon: 'food', color: '#d68f4e' },
  { name: 'Kitchenware', icon: 'kitchenware', color: '#959595' },
  { name: 'Nature', icon: 'nature', color: '#519b2c' },
  { name: 'Other', icon: 'others', color: '#9c6db8' },
  { name: 'Pets', icon: 'pets', color: '#40ac7b' },
  { name: 'Sports', icon: 'sport', color: '#548f89' },
  {
    name: 'Stationery & Branding',
    icon: 'stationery',
    color: '#6c82be',
  },
  { name: 'Tech & Devices', icon: 'entertainment', color: '#c6595f' },
];

class FakeSearch extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { searchThumbsOld, searchThumbsNew } = this.props.data;
    this.freshGreenThumbs = searchThumbsOld.edges.map((e) => {
      return (
        searchThumbsNew.edges.find((ne) =>
          ne.node.name.startsWith(e.node.name),
        ) || e
      );
    });
    this.state = {
      keyword: 'fresh',
      searchThumbs: searchThumbsOld.edges,
      animationInterval: null,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        animationInterval: this.searchAnimationLoop(),
      });
    }, 3000);
  }

  componentWillUnmount() {
    const { animationInterval } = this.state;
    if (animationInterval) {
      clearInterval(animationInterval);
    }
  }

  searchAnimationLoop() {
    return setInterval(() => {
      this.searchAnimationShowNew();
      setTimeout(() => {
        this.searchAnimationShowOld();
      }, 2000 + animationDuration);
    }, 3000 + 3 * animationDuration);
  }

  searchAnimationShowNew() {
    for (let i = 1; i <= chars; i++) {
      setTimeout(() => {
        this.setState({
          keyword: this.state.keyword + addText[i - 1],
        });
      }, i * keypressDuration);
    }
    setTimeout(() => {
      this.setState({
        searchThumbs: this.freshGreenThumbs,
      });
    }, chars * keypressDuration + loadingTime);
  }

  searchAnimationShowOld() {
    for (let i = 1; i <= chars; i++) {
      setTimeout(() => {
        this.setState({
          keyword: this.state.keyword.slice(0, -1),
        });
      }, (i * keypressDuration) / 2);
    }
    setTimeout(() => {
      this.setState({
        searchThumbs: this.props.data.searchThumbsOld.edges,
      });
    }, (chars * keypressDuration) / 2 + loadingTime);
  }

  render() {
    const { keyword, searchThumbs } = this.state;
    const searchThumbCards = searchThumbs
      .map(idAndFluid)
      .map(({ id, img }) => (
        <div key={id} className="card">
          <Img fluid={img} />
        </div>
      ));

    const categoryItems = categories.map((cat, idx) => (
      <li
        key={idx}
        className={idx === this.state.activeIndex ? 'active' : ''}
        onClick={() => this.setState({ activeIndex: idx })}
      >
        <i
          style={{ color: cat.color }}
          className={'icon-cat-' + cat.icon}
        ></i>
        <span>{cat.name}</span>
      </li>
    ));

    return (
      <section className="text-center search content-padding">
        <div className="content-container">
          <div className="headline less-margin">
            <h2>1000+ Isolated Items</h2>
            <p>
              No more fiddling around with stock photo licenses and
              incompatible graphics.
              <br />
              Build custom designs with our high-quality image library
              spread across these categories:
            </p>
          </div>

          <ul className="grid categories">{categoryItems}</ul>

          <p className="margin-top margin-bottom">
            Find exactly what you're looking for with the convenient
            live search. <br />
            Items are tagged by color, name, material and more.
          </p>

          <div id="fake-search" className="input">
            <span>{keyword}</span>
            <i className="icon-search" />
          </div>
          <div className="search-results">{searchThumbCards}</div>
        </div>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query FakeSearchQuery {
        searchThumbsOld: allFile(
          filter: {
            relativeDirectory: { eq: "search-thumbs" }
            name: { glob: "!*_new" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 329, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              name
            }
          }
        }
        searchThumbsNew: allFile(
          filter: {
            relativeDirectory: { eq: "search-thumbs" }
            name: { glob: "*_new" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 329, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              name
            }
          }
        }
      }
    `}
    render={(data) => <FakeSearch data={data} {...props} />}
  />
);
