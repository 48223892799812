import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import './UsecasePreview.scss';
import { idAndFluid } from '../utils/selectors';
import Img from 'gatsby-image';

const usecases = [
  'UI / UX Mockups',
  'Packaging Mockups',
  'Apparel Mockups',
  'Print / Branding Mockups',
  'Social Media Templates',
  'Hero Images / Website Headers',
  'Flatlays & Promo Graphics',
];

class UsecasePreview extends React.Component {
 
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeIndex: 0,
    };
  }

  render() {
    const { previews } = this.props.data;
    const previewImgElements = previews.edges
      .map(idAndFluid)
      .map(({ id, img }) => (
        <div key={id}>
          <Img className="image" fluid={img} alt="usecase preview" />
        </div>
      ));

    const listItems = usecases.map((usecase, idx) => (
      <li
        key={idx}
        className={idx === this.state.activeIndex ? 'active' : ''}
        onClick={() => this.setState({ activeIndex: idx })}
      >
        {usecase}
      </li>
    ));

    const activePreview = previewImgElements[this.state.activeIndex]

    return (
      <section id="usecases" className="content-padding usecases">
        <div className="content-container">
          <div className="headline text-center">
            <h2>Various Use Cases</h2>
            <span>
              Here's a quick overview of what you can design with
              SceneLab.
            </span>
          </div>

          <div className="grid">
            <ul>{listItems}</ul>
            <div className="image-wrapper">{activePreview || <div className="placeholder"></div>}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query UsecasePreviewQuery {
        previews: allFile(
          filter: { relativeDirectory: { eq: "usecase-previews" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <UsecasePreview data={data} {...props} />}
  />
);
